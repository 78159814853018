import { classNames } from "@ds/classNames"
import { Tag } from "@ds/tag/Tag"
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react"
import { ReactNode } from "react"

type TabsProps = {
  onChange?: (index: number) => void
  selectedIndex?: number
  children: {
    title: string
    showCount?: boolean
    count?: number
    content: ReactNode
    disable?: boolean
  }[]
  headerActions?: ReactNode[]
  fullWidth?: boolean
}

export const Tabs = ({ onChange, children, headerActions, selectedIndex, fullWidth }: TabsProps) => {
  return (
    <TabGroup onChange={onChange} selectedIndex={selectedIndex}>
      <TabList className="mb-6 flex justify-between">
        <div className={classNames("flex gap-8 border-b pt-4 text-h5 font-semibold", fullWidth && "w-full")}>
          {children.map(({ title, count, showCount = false, disable = false }) => (
            <Tab
              key={title}
              disabled={disable}
              className="flex gap-1 px-1 pb-4 outline-none ui-selected:border-b ui-selected:border-black ui-selected:text-dark-plum ui-not-selected:text-p-small ui-not-selected:text-grey ui-not-selected:hover:text-black"
            >
              {({ selected }) => (
                <>
                  <div>{title}</div>
                  {showCount && <Tag content={count || 0} disabled={!selected || count == 0} />}
                </>
              )}
            </Tab>
          ))}
        </div>
        {headerActions && (
          <div className="flex items-center gap-2 py-2">
            {headerActions.map((node, index) => (
              <div key={index}>{node}</div>
            ))}
          </div>
        )}
      </TabList>
      <TabPanels>
        {children.map(({ title, content }) => (
          <TabPanel key={title}>{content}</TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  )
}
