import { addEditorWrapper$, realmPlugin } from "@mdxeditor/editor"
import { OnFocusDisplayChildWrapper } from "./OnFocusDisplayChildWrapper"
import { ReactNode } from "react"

export const onFocusDisplayPlugin = (child: ReactNode) =>
  realmPlugin({
    init(realm) {
      realm.pubIn({
        [addEditorWrapper$]: OnFocusDisplayChildWrapper(child),
      })
    },
  })
