import { graphql } from "@data/gql"
import { RendezVousParIdentifiantQuery } from "@data/gql/graphql"

export type SyntheseAPI = NonNullable<
  RendezVousParIdentifiantQuery["rendezVousParIdentifiant"]["preconsultation"]["synthese"]
>
export type PatientAPI = NonNullable<RendezVousParIdentifiantQuery["rendezVousParIdentifiant"]["patient"]>
export type QuestionnaireAPI = NonNullable<
  RendezVousParIdentifiantQuery["rendezVousParIdentifiant"]["preconsultation"]["preparation"]["questionnaire"]
>

export const rendezVousParIdentifiantQuery = graphql(`
  query RendezVousParIdentifiant($identifiant: String!) {
    rendezVousParIdentifiant(identifiant: $identifiant) {
      id
      dateHeureDeDebut
      motif
      consultationTerminee
      patient {
        id
        prenom
        nom
        sexe
        dateDeNaissance
        compte {
          identifiantUtilisateur
          numeroDeTelephone
          email
        }
        lienDoctolib
        nouveauPatient
      }
      medecin {
        civilite
        nom
        photo
        nomLibelleLong
      }
      preconsultation {
        statutDePreconsultation
        preparation {
          statut
          viaLLM
          questionnaire {
            identifiant
            libelleQuestion
            libelleReponse
          }
        }
        validee
        motif {
          nom
          precisionMotif
        }
        synthese {
          statut
          contenu
          dateDeMiseAJour
          miseAJourPar
        }
        donneesBiometriques {
          pressionArterielleSystolique {
            valeurEnMmHg
          }
          pressionArterielleDiastolique {
            valeurEnMmHg
          }
          temperature {
            valeurEnDegresCelcius
          }
          frequenceCardiaque {
            valeurEnBPM
          }
          taille {
            valeurEnCm
          }
          poids {
            valeurEnKg
          }
          saturationEnOxygene {
            valeurEnPourcentage
          }
        }
        vaccins {
          id
          idDoctolib
          nom
          numeroDeLot
          dateDExpiration
          dateDInjection
        }
      }
    }
  }
`)
