import { classNames } from "@ds/classNames"
import { ReactNode } from "react"

type EnteteTagProps = {
  color: string
  content: string
  icon?: ReactNode
}

export const EnteteTag = ({ color, content, icon }: EnteteTagProps) => (
  <div className={classNames("flex h-5 items-center gap-2 rounded px-2 py-1 text-h7", color)}>
    {icon && icon}
    {content.toUpperCase()}
  </div>
)
