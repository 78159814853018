import { RouterProvider } from "@tanstack/react-router"
import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import { AuthProvider, Protected, useAuth } from "@infra/auth"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import "./i18n.ts"
import { register } from "@di"
import { register as registerPages } from "@infra/navigation/router"
import { SelecteurDeCabinet } from "@features/cabinet/SelecteurDeCabinet.tsx"
import { createRouter } from "@infra/navigation/router.tsx"
import { Consultations } from "./features/rendezVous/Consultations.page.tsx"
import { ChargementDesCabinets } from "@features/cabinet/ChargementDesCabinets.page.tsx"
import { DetailDUnePreconsultation } from "@features/rendezVous/detailPreconsultation/pages/DetailDUnePreconsultation.page.tsx"
import { RecherchePatientModal } from "@features/patient/pages/RecherchePatient.modal.tsx"
import { QuestionnairePanel } from "@features/rendezVous/detailPreconsultation/pages/QuestionnairePanel.page.tsx"
import { Patient } from "@features/patient/pages/Patient.page.tsx"
import { ShimmerProvider } from "@ds/animation/Shimmer.tsx"
import { AnalyticsIdentityBridge } from "@infra/analytics/AnalyticsIdentityBridge.ts"
import { MixpanelAnalytics } from "@infra/analytics/MixpanelAnalytics.ts"
import { registerSW } from "virtual:pwa-register"
import { UserProfile } from "@features/user/UserProfile.tsx"
import { CallbackUpdateService } from "@infra/updates/CallbackUpdateService.ts"
import * as Sentry from "@sentry/react"
import { asyncWithLDProvider, basicLogger } from "launchdarkly-react-client-sdk"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { StreamChatProvider } from "@infra/messagerie/StreamChatProvider.tsx"
import { MessagerieCabinet } from "@features/messagerie/MessagerieCabinet.tsx"
import { UnreadCount } from "@features/messagerie/UnreadCount.tsx"
import { pdfjs } from "react-pdf"
import "react-pdf/dist/Page/TextLayer.css"
import "react-pdf/dist/Page/AnnotationLayer.css"
import { Conversation } from "@features/messagerie/Conversation.tsx"
import { NoChannel } from "@features/messagerie/NoChannel.tsx"
import { ContextePatientPanel } from "@features/patient/pages/ContextePatientPanel.tsx"

const router = createRouter()

pdfjs.GlobalWorkerOptions.workerSrc = new URL("/pdf.worker.mjs", import.meta.url).toString()

Sentry.init({
  enabled: import.meta.env.PROD,
  environment: import.meta.env.MODE,
  dsn: "https://90c2e51c41c99b278eefac1f029b208b@o4507311965732864.ingest.de.sentry.io/4507312025305168",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.tanstackRouterBrowserTracingIntegration(router),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [import.meta.env.VITE_FRONT_URL as string, import.meta.env.VITE_BACKEND_URL as string],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const rootElement = document.getElementById("root")!

const updateSW = registerSW({
  onNeedRefresh() {
    updateService.canUpdate = true
  },
  onRegisteredSW(_, registration) {
    if (registration) {
      setInterval(() => {
        registration.update()
      }, 60 * 1000) // Check for updates every minute
    }
  },
})

const updateService = new CallbackUpdateService(updateSW)

if ("serviceWorker" in navigator && import.meta.env.PROD) {
  navigator.serviceWorker.register("/service-worker.js", {
    type: "classic",
  })
}

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  register({
    UnreadCount: UnreadCount,
    SelecteurDeCabinet,
    RecherchePatientModal,
    analytics: new MixpanelAnalytics(),
    useAuth: useAuth,
    updateServiceWorker: updateSW,
    UserProfile,
    updateService,
  })

  registerPages({
    ChargementPage: ChargementDesCabinets,
    ConsultationsPage: Consultations,
    DetailPreconsultationPage: DetailDUnePreconsultation,
    QuestionnairePanel: QuestionnairePanel,
    PatientPage: Patient,
    MessageriePage: MessagerieCabinet,
    ConversationPage: Conversation,
    MessagerieIndexPage: NoChannel,
    ContextePatientPanel: ContextePatientPanel,
  })

  const renderApp = async () => {
    const LaunchDarklyProvider = await asyncWithLDProvider({
      clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID as string,
      options: {
        logger: basicLogger({ level: "error" }),
      },
    })

    root.render(
      <React.StrictMode>
        <ShimmerProvider>
          <AuthProvider>
            <Protected
              component={() => (
                <LaunchDarklyProvider>
                  <AnalyticsIdentityBridge />
                  <QueryClientProvider client={queryClient}>
                    <StreamChatProvider>
                      <RouterProvider router={router} />
                      <ReactQueryDevtools />
                    </StreamChatProvider>
                  </QueryClientProvider>
                </LaunchDarklyProvider>
              )}
            ></Protected>
          </AuthProvider>
        </ShimmerProvider>
      </React.StrictMode>,
    )
  }

  renderApp()
}
