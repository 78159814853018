import { SVGProps } from "react"

export const EyeFilled = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 19" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33831 9.85025C2.22056 9.632 2.22056 9.36725 2.33831 9.149C3.75731 6.52475 6.37856 4.25 8.99981 4.25C11.6211 4.25 14.2423 6.52475 15.6613 9.14975C15.7791 9.368 15.7791 9.63275 15.6613 9.851C14.2423 12.4752 11.6211 14.75 8.99981 14.75C6.37856 14.75 3.75731 12.4752 2.33831 9.85025Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="currentColor"
      />
      <path
        d="M10.591 7.90901C11.4697 8.78769 11.4697 10.2123 10.591 11.091C9.71231 11.9697 8.28769 11.9697 7.40901 11.091C6.53033 10.2123 6.53033 8.78769 7.40901 7.90901C8.28769 7.03033 9.71231 7.03033 10.591 7.90901"
        fill="white"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
