import { ComponentType } from "react"
import { ReactNode } from "@tanstack/react-router"

export const OnFocusDisplayChildWrapper =
  (
    child: ReactNode,
  ): ComponentType<{
    children: ReactNode
  }> =>
  ({ children }) => {
    return (
      <div className="group">
        <div className="hidden group-has-[:focus-within]:block">{child}</div>
        {children}
      </div>
    )
  }
