import { SidePanel } from "@ds/layout/SidePanel"
import { contextePatientPanelRoute, preconsultationRoute } from "@infra/navigation/router"
import { useNavigate } from "@tanstack/react-router"
import { ContextePatient } from "../components/ContextePatient"
import { BoutonMettreEnFormeSyntheseContexte } from "../components/BoutonMettreEnFormeSyntheseContexte"
import { useGraphQLQuery } from "@data/useGraphQL"
import { patientParIdentifiantQuery } from "@data/patient/patientParIdentifiantQuery"

export const ContextePatientPanel = () => {
  const navigate = useNavigate()
  const { id } = preconsultationRoute.useParams()
  const { identifiantProfil } = contextePatientPanelRoute.useParams()
  const close = () => navigate({ to: preconsultationRoute.to, params: { id } })

  const { data } = useGraphQLQuery({
    document: patientParIdentifiantQuery,
    variables: { identifiant: identifiantProfil },
  })

  return (
    <SidePanel onClose={close}>
      {data?.data?.patientParIdentifiant != undefined && (
        <ContextePatient
          identifiantProfil={identifiantProfil}
          align={"column"}
          patient={data?.data?.patientParIdentifiant}
          boutonMettreEnForme={BoutonMettreEnFormeSyntheseContexte}
        />
      )}
    </SidePanel>
  )
}
