import { SVGProps } from "react"

export const Send = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.56642 9.11175L7.89467 15.0885C8.06042 15.8333 9.04967 15.9938 9.44192 15.3398L15.6287 5.028C15.9669 4.4655 15.5619 3.75 14.9057 3.75L3.24242 3.75C2.49017 3.75 2.11367 4.659 2.64542 5.19075L6.56642 9.11175Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.631 4.16992L6.56348 9.11242"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
