import { CheckRound } from "@ds/icons/CheckRound"
import { format } from "date-fns"
import {
  RendezVousParCabinetAPI,
  rendezVousParCabinetQuery,
} from "@data/rendezVous/queries/rendezVousParCabinetQuery.ts"
import { LieuDuRendezVous, StatutDePreparation } from "@data/gql/graphql"
import { Avatar } from "@ds/avatar/Avatar"
import { classNames } from "@ds/classNames"
import { Subtract } from "@ds/icons/Subtract"
import { fr } from "date-fns/locale"
import { t, TFunction } from "i18next"
import { capitalize } from "@utils/capitalize"
import { agePatient } from "@features/patient/agePatient"
import { Cross } from "@ds/icons/Cross.tsx"
import { useGQLMutation } from "@data/useGraphQL.ts"
import { marquerPreconsultationANePasFaire } from "@data/preconsultation/mutations/marquerPreconsultationANePasFaire.ts"
import { usePreferences } from "@infra/preferences/usePreferences.tsx"
import { MouseEventHandler } from "react"

type InformationsDeRendezVousProps = {
  rendezVous: RendezVousParCabinetAPI
  displayPatientName?: boolean
  formatDate?: string
  onClick: () => unknown
  peutMarquerANePasFaire: boolean
}

export const InformationsDeRendezVous = ({
  rendezVous,
  formatDate = "HH:mm",
  displayPatientName = true,
  onClick,
  peutMarquerANePasFaire,
}: InformationsDeRendezVousProps) => {
  const { id, dateHeureDeDebut, medecin, patient, motif, patientEnSalleDAttente, preconsultation, lieu } = rendezVous
  const heure = format(dateHeureDeDebut, formatDate, { locale: fr }).toUpperCase()
  const informationsPatient = deduireInformationsPatient(patient, t)
  const { identifiantDuCabinet } = usePreferences()

  const preparationTerminee = preconsultation?.preparation.statut == StatutDePreparation.PreparationTerminee

  const { mutate: marquerANePasFaire } = useGQLMutation(marquerPreconsultationANePasFaire, {
    invalidateQueryKeys: [[rendezVousParCabinetQuery, { identifiantDuCabinet }], [rendezVousParCabinetQuery]],
  })

  return (
    <>
      <div
        key={id}
        data-testid={id}
        onClick={onClick}
        className={classNames(
          "group flex h-14 cursor-pointer gap-2 rounded-xl py-4 pr-4 shadow-very-light-shadow hover:shadow-hover-drop-shadow",
          { "opacity-50": rendezVous.consultationTerminee },
          {
            "bg-secondary-blue-light": patientEnSalleDAttente,
          },
        )}
      >
        <div className={classNames("flex min-w-0 flex-1 items-center gap-6")}>
          <div className="ml-6 flex h-5 items-center rounded bg-extra-light-grey p-1 text-h7">{heure}</div>
          {displayPatientName && (
            <div className="flex min-w-0 flex-shrink gap-2">
              <div className="truncate text-p-small" data-testid="informations-patient">
                {informationsPatient}
              </div>
              {patient?.nouveauPatient && <div className="text-h5 text-blue-ia">{t("nouveau")}</div>}
            </div>
          )}
        </div>
        <div className="flex flex-[0.5] items-center gap-2">
          {medecin?.photo ? (
            <img className="h-5 w-5 rounded" src={medecin.photo} />
          ) : (
            <Avatar name={medecin.nomLibelleLong.replace("Dr. ", "")} style="small" />
          )}
          <div className="truncate text-p-small">
            {medecin.civilite ? medecin.civilite + " " + medecin.nom : medecin.nomLibelleLong}
          </div>
        </div>
        <div className="flex min-w-0 flex-[0.8] items-center">
          <div className="truncate text-p-small">
            {lieu == LieuDuRendezVous.EnTeleconsultation ? t("teleconsultation") : motif}
          </div>
        </div>
        <div className={"flex h-6 w-12 items-center justify-center gap-4 rounded"}>
          {rendezVous.preconsultation.validee ? (
            <Subtract data-testid="preparation-validee" className="text-h4 text-blue-ia" />
          ) : (
            <CheckRound
              data-testid="check"
              className={classNames("w-[14px]", preparationTerminee ? "text-blue-ia" : "invisible")}
            />
          )}
          {peutMarquerANePasFaire && (
            <div
              title={t("retirerListeAPreparer")}
              onClick={
                (async (event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  await marquerANePasFaire({ identifiantDuRendezVous: rendezVous.id })
                }) as MouseEventHandler<HTMLDivElement>
              }
            >
              <Cross className="text-p-small text-blue-ia opacity-0 group-hover:opacity-100" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const deduireInformationsPatient = (
  patient: RendezVousParCabinetAPI["patient"],
  t: TFunction<"translation", undefined>,
) => {
  const sexe = patient?.sexe ? "   " + t(patient.sexe).charAt(0) : ""
  const age = patient?.dateDeNaissance ? " - " + agePatient(patient.dateDeNaissance, t) : ""
  return capitalize(patient?.prenom) + " " + patient.nom?.toUpperCase() + sexe + age
}
