import { graphql } from "@data/gql"

export const enregistrerHabitudeDeVie = graphql(`
  mutation enregistrerHabitudeDeVie(
    $identifiantProfilPatient: String!
    $identifiantHabitudeDeVie: String!
    $valeur: String!
  ) {
    enregistrerHabitudeDeVie(
      identifiantProfilPatient: $identifiantProfilPatient
      identifiantHabitudeDeVie: $identifiantHabitudeDeVie
      valeur: $valeur
    )
  }
`)
