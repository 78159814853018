import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react"
import { Eye } from "@ds/icons/Eye.tsx"
import { agePatient } from "@features/patient/agePatient.ts"
import { useTranslation } from "react-i18next"
import { Emoji } from "@features/messagerie/Emoji.tsx"
import { useGraphQLQuery } from "@data/useGraphQL.ts"
import { rendezVousQuery } from "@data/rendezVous/queries/rendezVous.ts"
import { ArrowRight } from "@ds/icons/ArrowRight.tsx"
import { conversationRoute, patientRoute } from "@infra/navigation/router.tsx"
import { Avatar } from "@ds/avatar/Avatar.tsx"
import { RendezVousQueryQuery } from "@data/gql/graphql.ts"
import { NoPrimaryApp } from "@ds/icons/NoPrimaryApp.tsx"
import { PrimaryApp } from "@ds/icons/PrimaryApp.tsx"
import { dateHeureRelative, dateRelative } from "@utils/dateHeure.ts"
import { useEffect, useState } from "react"
import { useChannelStateContext, useChatContext } from "stream-chat-react"
import { Channel } from "stream-chat"
import { Participant, ParticipantProps } from "@features/messagerie/Participant.tsx"
import { classNames } from "@ds/classNames.ts"
import { usePreferences } from "@infra/preferences/usePreferences.tsx"

export interface PatientProps {
  id: string
  nom: string
  prenom?: string | null
  sexe?: string | null
  dateDeNaissance?: Date | null
}

export interface ApercuPatientProps {
  patient?: PatientProps | null
  profilPrincipal: boolean | null
  nonOnboarde: boolean | null
}

const DetailRendezVous = ({
  rendezVous,
  avecHeures,
}: {
  rendezVous: RendezVousQueryQuery["rendezVous"][number]
  avecHeures: boolean
}) => {
  return (
    <div className="flex items-start gap-1 p-1">
      <div>
        {rendezVous.medecin?.photo ? (
          <img className="h-5 w-5 rounded-full" src={rendezVous.medecin.photo} />
        ) : (
          <Avatar name={rendezVous.medecin.nomLibelleLong.replace("Dr. ", "")} style="small" />
        )}
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-p-tiny first-letter:uppercase">
          {avecHeures
            ? dateHeureRelative(new Date(rendezVous.dateHeureDeDebut))
            : dateRelative(new Date(rendezVous.dateHeureDeDebut))}
        </div>
        <div className="text-ellipsis text-p-tiny">{rendezVous.preconsultation.motif?.nom || rendezVous.motif}</div>
      </div>
    </div>
  )
}

const DetailConversation = ({ conversation }: { conversation: Channel }) => {
  const assistanteMedicalePrimary =
    conversation.state?.members &&
    Object.values(conversation?.state?.members)
      .sort((member1, member2) => {
        if (
          member1.updated_at &&
          member2.updated_at &&
          member1.updated_at.trim() !== "" &&
          member2.updated_at.trim() !== ""
        ) {
          return new Date(member2.updated_at).getTime() - new Date(member1.updated_at).getTime()
        }
        return 0
      })
      .map((member) => member.user)
      .filter((user) => user?.role === "soignant")
      .filter((user) => user?.metier === "assistante_medicale")
      .map((user) => user as unknown as ParticipantProps)
      .reverse()
      .pop()

  const medecinPrimary =
    conversation.state?.members &&
    Object.values(conversation?.state?.members)
      .sort((member1, member2) => {
        if (member1.updated_at && member2.updated_at) {
          return new Date(member2.updated_at).getTime() - new Date(member1.updated_at).getTime()
        }
        return 0
      })
      .map((member) => member.user)
      .filter((user) => user?.role === "soignant")
      .filter((user) => user?.metier === "medecin")
      .map((user) => user as unknown as ParticipantProps)
      .reverse()
      .pop()

  const urlConversation = conversationRoute.to.replace(/\$conversationId/, conversation.id ?? "")

  return (
    <div
      className="w-full cursor-pointer rounded-md p-1 hover:bg-extra-light-grey"
      onClick={() => window.open(urlConversation, "_blank")}
    >
      <div className="flex items-start gap-1">
        <div className="relative flex flex-row gap-1">
          <div className="flex -space-x-1">
            {assistanteMedicalePrimary && (
              <Participant
                {...assistanteMedicalePrimary}
                className={"border border-white text-h7 group-hover:border-extra-light-grey"}
              />
            )}
            {!assistanteMedicalePrimary && medecinPrimary && (
              <Participant
                key={medecinPrimary.id}
                {...medecinPrimary}
                className={"border border-white text-h7 group-hover:border-extra-light-grey"}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="text-p-tiny first-letter:uppercase">
            {conversation.lastMessage() &&
              conversation.lastMessage()?.created_at &&
              dateRelative(conversation!.lastMessage()!.created_at!)}
          </div>
          <div className="text-ellipsis text-p-tiny">{conversation.data?.objet as string}</div>
        </div>
      </div>
    </div>
  )
}

const DetailPatient = ({ patient, nonOnboarde }: { patient?: PatientProps | null; nonOnboarde: boolean | null }) => {
  const { t } = useTranslation()
  const urlFichePatient = patientRoute.to.replace(/\$id/, patient?.id ?? "")

  const { data } = useGraphQLQuery({
    document: rendezVousQuery,
    variables: { identifiantProfil: patient!.id },
    enabled: patient !== undefined && patient !== null,
  })

  const [conversations, setConversations] = useState<Channel[]>([])

  const { client } = useChatContext()
  const { channel } = useChannelStateContext()

  const { identifiantDuCabinet } = usePreferences()

  useEffect(() => {
    const chargerConversations = async () => {
      const conversations = await client.queryChannels(
        {
          idProfilPatient: patient!.id,
          last_message_at: { $exists: true },
          objet: { $exists: true },
          team: identifiantDuCabinet,
        },
        { last_message_at: -1 },
        { limit: 3 },
      )
      const dernieresConversations = conversations.filter((conversation) => conversation.id !== channel.id).slice(0, 2)
      setConversations(dernieresConversations)
    }
    if (client && channel?.id && conversations.length === 0) {
      chargerConversations()
    }
  }, [client, channel, conversations])

  const prochainRendezVous = data?.data?.rendezVous
    .sort((rdv1, rdv2) => new Date(rdv1.dateHeureDeDebut).getTime() - new Date(rdv2.dateHeureDeDebut).getTime())
    .find((rdv) => new Date(rdv.dateHeureDeDebut) >= new Date())
  const dernierRendezVous = data?.data?.rendezVous
    .sort((rdv1, rdv2) => new Date(rdv2.dateHeureDeDebut).getTime() - new Date(rdv1.dateHeureDeDebut).getTime())
    .find((rdv) => new Date(rdv.dateHeureDeDebut) < new Date())

  return (
    <PopoverPanel
      anchor="bottom start"
      transition
      className="w-62 mt-1 flex flex-col gap-4 rounded-lg bg-white p-3 text-dark-plum shadow-drop-shadow transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
    >
      <div className="flex flex-col gap-1">
        <div className="flex gap-1">
          <div className="text-h5">{(patient?.prenom ?? "") + " " + (patient?.nom?.toUpperCase() ?? "")}</div>
          {nonOnboarde === true && <NoPrimaryApp data-testid="no-app" />}
          {nonOnboarde === false && <PrimaryApp data-testid="app" />}
        </div>
        <div className="flex items-center gap-1 text-p-tiny">
          <div>
            <Emoji dateDeNaissance={patient?.dateDeNaissance} sexe={patient?.sexe} />
          </div>
          <div>{patient?.dateDeNaissance && agePatient(patient?.dateDeNaissance, t)}</div>
        </div>
      </div>
      {(prochainRendezVous || dernierRendezVous) && (
        <div className="flex flex-col gap-2">
          <div className="flex items-center">
            <div className="text-h7">{t("messagerie.patientRdvProches")}</div>
            <div className="flex-grow"></div>
            <div
              className="flex cursor-pointer items-center gap-1"
              onClick={() => window.open(urlFichePatient, "_blank")}
            >
              <div className="text-p-tiny">{t("toutVoir")}</div>
              <div>
                <ArrowRight />
              </div>
            </div>
          </div>
          {prochainRendezVous && <DetailRendezVous rendezVous={prochainRendezVous} avecHeures={true} />}
          {dernierRendezVous && <DetailRendezVous rendezVous={dernierRendezVous} avecHeures={false} />}
        </div>
      )}
      {conversations.length > 0 && (
        <div className="flex flex-col gap-2">
          <div className="text-h7 uppercase">{t("messagerie.dernieresConversations")}</div>
          <div className="flex flex-col items-start gap-1">
            {conversations.map((conversation) => (
              <DetailConversation conversation={conversation} key={"conversation-" + conversation.id} />
            ))}
          </div>
        </div>
      )}
    </PopoverPanel>
  )
}

export const ApercuPatient = ({ patient, profilPrincipal, nonOnboarde }: ApercuPatientProps) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <PopoverButton
            className={classNames(
              "flex cursor-pointer flex-row items-center px-1 py-0.5 text-h7 focus-visible:outline-none",
              {
                "gap-1 rounded bg-secondary-blue": !profilPrincipal,
              },
            )}
            data-testid="bouton-apercu-patient"
          >
            {!profilPrincipal && (patient?.prenom?.toUpperCase() ?? "") + " " + (patient?.nom?.toUpperCase() ?? "")}
            <Eye className="text-h5" />
          </PopoverButton>
          {open && <DetailPatient patient={patient} nonOnboarde={nonOnboarde} />}
        </>
      )}
    </Popover>
  )
}
