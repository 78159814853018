import { SVGProps } from "react"

export const ArrowsRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.9132 5.89441L15.0562 10.0845L10.8661 14.2276M5.07993 5.86147L9.223 10.0516L5.03287 14.1947"
      stroke="#1E1E1E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
