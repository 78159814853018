import { ArrowsRight } from "@ds/icons/ArrowsRight"
import { Dialog, DialogPanel } from "@headlessui/react"

type SidePanelProps = {
  onClose: () => void
  children: React.ReactNode
}

export const SidePanel = ({ onClose, children }: SidePanelProps) => {
  return (
    <Dialog className="relative z-50" onClose={onClose} open>
      <div className="fixed inset-0 animate-fadein bg-secondary-black" data-testid="backdrop" onClick={onClose} />
      <DialogPanel className="fixed bottom-0 right-0 top-0 w-1/2 max-w-[600px] animate-slide-from-right overflow-auto bg-white p-6">
        <button
          onClick={onClose}
          className="cursor-pointer rounded-md p-1 text-2xl hover:text-light-grey hover:shadow-hover-drop-shadow"
          data-testid="close-button"
        >
          <ArrowsRight />
        </button>
        {children}
      </DialogPanel>
    </Dialog>
  )
}
